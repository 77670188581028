html,
body,
#root,
.split-pane {
  height: 100%;
}

.video-player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.user-content {
  h1 {
    font-size: 2rem;
    margin: 1.4rem 0 1.2rem 0;
  }
  h2 {
    font-size: 1.7rem;
    margin: 1.2rem 0 1rem 0;
  }
  h3 {
    font-size: 1.4rem;
    margin: 1rem 0;
  }
  h4 {
    font-size: 1.2rem;
    margin: 1rem 0;
  }
  p,
  ul,
  ol {
    margin: 0.7rem 0;
  }
  ul li {
    margin: 0.3rem 0;
  }
  p,
  ul,
  h1,
  h2,
  h3,
  h4,
  h5 {
    &:first-child,
    &:last-child {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  a {
    // https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  a:link {
    font-weight: bold;
  }
  a:hover {
    text-decoration: underline;
  }
  ul,
  ol {
    margin-left: 2rem;
  }
  blockquote {
    padding: 0.3rem 0.5rem;
    border: 1px solid #eee;
    font-style: oblique;
    color: #888;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  table {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    td, th {
      text-align: left;
      border-bottom: 1px solid #aaa;
    }
    th {
      font-weight: bold;
    }
  }
}

.chakra-ui-dark {
  .user-content {
    blockquote {
      border-color: #333;
      color: #aaa;
    }
  }
}

$lightModeSCrollbarFg: #e2e8f0;
$lightModeScrollbarBg: #fff;

$darkModeSCrollbarFg: rgba(255, 255, 255, 0.3);
$darkModeScrollbarBg: #1a202c;

.scrollable {
  scrollbar-color: $lightModeSCrollbarFg $lightModeScrollbarBg;
  scrollbar-width: thin;
}

.scrollable::-webkit-scrollbar {
  width: 0.5em;
}

.scrollable::-webkit-scrollbar-track {
  box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.1);
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: $lightModeSCrollbarFg;
  border-radius: 0.5em;
}

.chakra-ui-dark {
  .scrollable {
    scrollbar-color: $darkModeSCrollbarFg $darkModeScrollbarBg;
  }
  .scrollable::-webkit-scrollbar-thumb {
    background-color: $darkModeSCrollbarFg;
  }
  .scrollable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.4);
  }
}

.fullscreen-gallery {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  .image {
    box-sizing: border-box;
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}
